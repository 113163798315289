<template>
  <div v-loading="loading">
    <el-result
      icon="success"
      title="成功提示"
      subTitle="关注赊销订货电子回执公证号可收到订阅信息"
    >
      <template slot="extra">
        <img
          src="@/assets/public.png"
          style="width: 100%; margin-top: 30px"
          alt=""
        />
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entity: {},
      loading: true,
    };
  },
  async mounted() {
    let query = location.href.split("?");
    if (query.length > 1) {
      console.log(query);
      let code = query[1].split("&")[0].split("=")[1];
      console.log(code);
      this.entity = this.$route.query;
      console.log(this.entity);
      // 获取access_token
      let result = await this.$http.get(
        "/Base_Manage/Home/GetWxAccessToken?code=" + code
      );
      console.log(JSON.parse(result.Data));
      let { openid: OpenId } = { ...JSON.parse(result.Data) };
      console.log(OpenId);
      // 保存
      this.$http
        .post("/BO/CustmerinfoUser/SaveData", {
          ...this.entity,
          OpenId,
        })
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.$message.success("订阅成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    } else {
      this.loading = false;
      this.$message.error("信息获取失败,请重新扫码尝试");
    }
  },
  methods: {
    back() {
      window.close();
    },
  },
};
</script>

<style></style>
